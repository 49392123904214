import React from 'react';
import { t } from '../../helpers';

export default class Loader extends React.Component {
  render() {
    return (
      <div className="loader">
        <div className="loader-container">
          <div className="lds-ripple ml-1"><div></div><div></div></div>
          <h3 className="mb-0 mt-n2 text-primary">{t('Loading')}</h3>
        </div>
      </div>
    );
  }
}
