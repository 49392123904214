import Gettext from 'node-gettext';
import moment from 'moment';
import en from '../lang/en_GB.json';
import de from '../lang/de_DE.json';
import is from '../lang/is_IS.json';
import ru from '../lang/ru_RU.json';
import ro from '../lang/ro_RO.json';

export const gt = new Gettext();

const translations = {
  'en-gb': en,
  'de-de': de,
  'is-is': is,
  'ru-ru': ru,
  'ro-ro': ro
};

moment.updateLocale('en-gb', {
  weekdaysMin: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'],
  week: {
    dow: 1
  }
});

moment.locale('de-de', {
  weekdaysMin: ['so', 'mo', 'di', 'mi', 'do', 'fr', 'sa'],
  week: {
    dow: 1
  }
});

moment.locale('is-is', {
  weekdaysMin: ['su', 'má', 'þr', 'mi', 'fi', 'fö', 'la'],
  week: {
    dow: 1
  }
});
moment.locale('ru-ru', {
  weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  week: {
    dow: 1
  }
});

moment.locale('ro-ro', {
  weekdaysMin: 'Du_Lu_Ma_Mi_Jo_Vi_Sâ'.split('_'),
  week: {
    dow: 1
  }
});

const locales = ['en-gb', 'de-de', 'is-is', 'ru-ru', 'ro-ro'];
locales.forEach((locale) => {
  gt.addTranslations(locale, 'messages', translations[locale]);
});

const locale = window.conf.defaultLocale;
gt.setLocale(locale);
moment.locale(locale);

export const t = gt.gettext.bind(gt);
export const dt = gt.dgettext.bind(gt);

export const setLocale = locale => {
  moment.locale(locale);
  gt.setLocale(locale);
};

export default gt;
