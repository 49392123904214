import React from 'react';
import PropTypes from 'prop-types';
import NotFound from '../NotFound';
import SliceZone from '../slices/Slice';
import Footer from '../layout/Footer';
import Loader from '../Loader';
import Header from '../layout/Header';
import { languageToUrl, urlToLanguage, setLocale, langToPrismic, initWidget, FETCH_LINKS_PAGE_TYPES } from '../../../helpers';
import HeroCampaign from '../slices/HeroCampaign';
import Helmet from 'react-helmet';

export default class Page extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      notFound: false
    };
    if (this.props.prismicCtx) {
      this.fetchPage(this.props);
    }
    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  componentDidMount() {
    if (!urlToLanguage[this.props.match.params.lang]) {
      return this.props.history.push('/');
    }
    if (this.props.match.params.lang !== languageToUrl[window.conf.defaultLocale]) {
      setLocale(urlToLanguage[this.props.match.params.lang]);
    }
  }
  componentWillUnmount() {
    try {
      if (window.BookingWidget) {
        window.BookingWidget.unmount();
      }
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    // We fetch the page only after it's ready to query the api
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
    if (this.props.match.params.lang !== languageToUrl[window.conf.defaultLocale]) {
      setLocale(urlToLanguage[this.props.match.params.lang]);
    }
    if (this.props.match.params.lang !== prevProps.match.params.lang) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle(
        'homepage',
        { lang: langToPrismic[urlToLanguage[props.match.params.lang] || window.conf.defaultLocale], fetchLinks: FETCH_LINKS_PAGE_TYPES },
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
            this.initWidget(doc);
          } else {
            this.setState({ notFound: !doc });
          }
        }
      );
    }
    return null;
  }
  initWidget(doc) {
    const lang = urlToLanguage[this.props.match.params.lang];
    initWidget(lang, doc.data);
  }
  onLanguageChange(newLang) {
    const inNewLang = this.state.doc.alternate_languages.find(
      l => l.lang === langToPrismic[newLang]
    );
    if (inNewLang) {
      setLocale(newLang);
      if (inNewLang.type === 'homepage') {
        this.props.history.push('/' + languageToUrl[newLang]);
      } else {
        this.props.history.push(
          '/' + languageToUrl[newLang] + '/' + inNewLang.uid
        );
      }
    } else {
      this.props.history.push('/');
    }
  }
  render() {
    if (this.state.doc) {
      const heroBgStyle = window.innerWidth > 768 ? {
                backgroundImage:
                  'url("' +
                  this.state.doc.data.hero_background_image.url +
                  '")',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              } : null;
      return (
        <div className="homepage" data-wio-id={this.state.doc.id}>
          <Helmet
            title={this.state.doc.data.meta_title}
            meta={[
              {
                name: 'description',
                content: this.state.doc.data.meta_description,
              },
              {
                property: 'og:title',
                content: this.state.doc.data.meta_title
              },
              { property: 'og:description', content: this.state.doc.data.meta_description },
              {
                property: 'og:image',
                content: this.state.doc.data.hero_background_image.url
              },
              { property: 'og:type', content: 'website' },
              { property: 'og:url', content: window.location.href }
            ]}
          />
          <Header {...this.props} onLanguageChange={this.onLanguageChange} />
          <section className="hero-vertical">
            <div
              className="img-container"
              style={heroBgStyle}
            >
              <div className={`container${this.state.doc.data.show_promo_code === 'true' ? ' narrow-padding' : ''}` }>
                <div
                  className="booking-widget-container"
                  id="booking-widget"
                ></div>
                <div className="campaign-container">
                  {this.state.doc.data.campaign.map((item, i) => (
                    <HeroCampaign
                      key={'camp-' + i}
                      campaign={item}
                      prismicCtx={this.props.prismicCtx}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>
          <SliceZone
            sliceZone={this.state.doc.data.page_content}
            prismicCtx={this.props.prismicCtx}
          />
          <Footer {...this.props} />
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return (
      <div className="content">
        <Header {...this.props} onLanguageChange={this.onLanguageChange} />
        <Loader />
        <Footer {...this.props} />
      </div>
    );
  }
}
