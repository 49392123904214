import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Link } from 'prismic-reactjs';

const HeroCampaign = ({ campaign, prismicCtx }) =>
    <div className="card campaign-card">
      <NavLink
        to={Link.url(
          campaign.campaign_link,
          prismicCtx.linkResolver
          )}>
        <div className="card-body" style={campaign.background_color ? { backgroundColor: campaign.background_color } : {}}>
          <div className="card-title" style={campaign.text_color ? { color: campaign.text_color } : {}}>
            {campaign.campaign_title}
          </div>
          <div className="card-text" style={campaign.text_color ? { color: campaign.text_color } : {}}>
            {campaign.campaign_text}
          </div>
        </div>
      </NavLink>
    </div>;

HeroCampaign.propTypes = {
  campaign: PropTypes.shape({
      campaign_title: PropTypes.string,
      campaign_text: PropTypes.string,
      campaign_link: PropTypes.object,
      background_color: PropTypes.string,
      text_color: PropTypes.string
    }).isRequired,
    prismicCtx: PropTypes.object.isRequired
};

export default HeroCampaign;
