import htmlSerializer from './htmlSerializer';
import { urlToLanguage, languageToUrl, langAsReadable, langToPrismic, prismicToLang } from './language';
import { t, setLocale } from './translator';
import TagManager from 'react-gtm-module';
import { initAWS } from './aws-logger';

const vehicleTypes = {
    M: t('Mini'),
    N: t('Mini Elite'),
    E: t('Economy'),
    H: t('Economy Elite'),
    C: t('Compact'),
    D: t('Compact Elite'),
    I: t('Intermediate'),
    J: t('Intermediate Elite'),
    S: t('Standard'),
    R: t('Standard Elite'),
    F: t('Fullsize'),
    G: t('Fullsize Elite'),
    P: t('Premium'),
    U: t('Premium Elite'),
    L: t('Luxury'),
    W: t('Luxury Elite'),
    O: t('Oversize'),
    X: t('Special')
};

export {
  htmlSerializer,
  urlToLanguage,
  languageToUrl,
  langAsReadable,
  langToPrismic,
  prismicToLang,
  vehicleTypes,
  t,
  setLocale,
  initAWS
};

export const addScriptUrlToHead = (url, id) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    if (id) {
      script.id = id;
    }
    script.onload = () => resolve();
    document.head.appendChild(script);
  });

let managerInitialized = false;

const enableGoogleTagManager = () => {
  if (window.conf.gtmTag && !managerInitialized) {
    const tagManagerArgs = {
      gtmId: window.conf.gtmTag
    };
    TagManager.initialize(tagManagerArgs);
    managerInitialized = true;
  }
};

export const loadCookieConsentSettings = () => {
  window.addEventListener('load', () => {
    enableGoogleTagManager();
    window.cookieconsent.initialise({
      onInitialise(status) {
        if (this.hasConsented('required')) {
          enableGoogleTagManager();
        }
        if (this.hasConsented('analytics')) {
          TagManager.dataLayer({ dataLayer: { event: 'enable-analytics', enableAnalytics: true } });
        }
      },
      onAllow(category) {
        if (category === 'required') {
          enableGoogleTagManager();
        }
        if (category === 'analytics') {
          TagManager.dataLayer({ dataLayer: { event: 'enable-analytics', enableAnalytics: true } });
        }
      },
      onRevoke(category) {
        if (category === 'required') {
          //
        }
        if (category === 'analytics') {
          TagManager.dataLayer({ dataLayer: { event: 'enable-analytics', enableAnalytics: false } });
        }
      }
    });
  });
};

export const getQueryParam = (param) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === param) {
            return decodeURIComponent(pair[1]);
        }
    }
    return null;
};

export const initWidget = (lang, data, container, wide, showTitle) => {
    const conf = {
      lang,
      allowOneWay: data.allow_one_way_rental
    };
    if (window.conf.bookingWidget) {
      conf.supplierId = window.conf.bookingWidget.supplierId;
      conf.source = window.conf.bookingWidget.source;
      conf.bookingWebUrl = window.conf.bookingWidget.bookingWeb;
      conf.fetchDesksApiKey = window.conf.bookingWidget.fetchDesksApiKey !== undefined ?
        window.conf.bookingWidget.fetchDesksApiKey : window.conf.reservationKey;
    }
    if (data.banner_label) {
      conf.banner = {
        label: data.banner_label,
        text: data.banner_text
      };
    }
    if (data.default_duration) {
      conf.defaultDuration = data.default_duration;
    }
    if (data.default_time) {
      conf.defaultTime = data.default_time;
    }
    if (data.default_pu_date_shift !== null && data.default_pu_date_shift !== undefined) {
      conf.dateShift = data.default_pu_date_shift;
    }
    if (data.default_pu_location) {
      conf.pickupDesk = data.default_pu_location.code;
    }
    if (data.driver_ages_selection && data.driver_ages_selection.length) {
      conf.ageSelection = data.driver_ages_selection.map(s => ({
        title: s.selection_title,
        value: s.driver_age_value
      }));
    }
    if (data.show_promo_code) {
      conf.enablePromoCode = data.show_promo_code === 'true';
    }
    if (data.default_promo_code && data.default_promo_code.length) {
      conf.defaultPromoCode = data.default_promo_code;
    }
    if (data.cars && data.cars.length) {
      const codes = data.cars.filter(c => c.car_info && c.car_info.acrissCode).map(c => c.car_info.acrissCode);
      conf.cars = codes;
      conf.hideRest = data.hide_rest;
    }
    if (data.car_info) {
      conf.cars = [data.car_info.acrissCode];
      conf.hideRest = data.hide_rest;
    }
    if (data.api_key) {
      conf.apiKey = data.api_key;
    }
    if (data.show_partner_reference) {
      conf.enablePartnerReference = data.show_partner_reference;
    }
    if (container) {
      conf.containerId = container;
    }
    if (wide !== undefined) {
      conf.wide = wide;
    }
    if (showTitle !== undefined) {
      conf.showTitle = showTitle;
    }
    try {
      if (window.BookingWidget) {
        window.BookingWidget.init(conf);
      }
    } catch (err) {
      console.log(err);
    }
};

export const FETCH_LINKS_PAGE_TYPES = ['location_page.parent', 'terms_and_conditions_subpage.parent', 'car_info_page.parent'];
