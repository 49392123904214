import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../../helpers';

export default class Columns extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }
  getClassNameForColumn(column) {
    let name = 'col-12';
    if (this.props.slice.items.length > 1) {
      name += ' col-md-6';
    }
    if (this.props.slice.items.length > 2) {
      name += ' col-xl-4';
    }
    if (column.list_bullet_style) {
      name += ' list-' + column.list_bullet_style.toLocaleLowerCase();
    }

    return name;
  }
  render() {
    return (
      <section className={this.props.slice.primary.has_gray_background === 'YES' ? 'columns-with-background' : '' }>
        <div className="container columns-container">
        <div className="row">
        {this.props.slice.items.map((item, i) =>
          (<div
            key={'col_' + i}
            className={this.getClassNameForColumn(item)}
          >
            <RichText render={item.column} linkResolver={this.props.prismicCtx.linkResolver} htmlSerializer={htmlSerializer} />
          </div>))}
        </div>
        </div>
      </section>
    );
  }
}
