import AWS from 'aws-sdk';

const getLatestSequenceToken = (conf, cloudwatchlogs, setLatestStream) => {
  const params = {
    logGroupName: conf.groupName,
    descending: true,
    logStreamNamePrefix: conf.streamPrefix
  };
  cloudwatchlogs.describeLogStreams(params, (err, data) => {
    if (err) console.log(err, err.stack);
    // an error occurred
    else {
      const streams = data.logStreams;
      streams.sort((a, b) => b.creationTime - a.creationTime);
      const latestLogStream = streams[0]; // latest
      setLatestStream(latestLogStream);
    } // successful response
  });
};

const sendErrorToLogs = (error, conf, latestLogStream, cloudwatchlogs) => {
  if (!error || !error.stack) {
    return;
  }
  const params = {
    logEvents: [
      {
        message: 'APP_ERROR: ' + error.stack + '; LOC: ' + window.location.href,
        timestamp: new Date().getTime()
      }
    ],
    logGroupName: conf.groupName,
    logStreamName: latestLogStream.logStreamName,
    sequenceToken: latestLogStream.uploadSequenceToken
  };
  cloudwatchlogs.putLogEvents(params, (err, data) => {
    if (err) console.log(err, err.stack);
    // an error occurred
    else {
      latestLogStream.uploadSequenceToken = data.nextSequenceToken;
    }
  });
};

// eslint-disable-next-line import/prefer-default-export
let cloudwatchlogs;
let awsCreds;

const shouldIgnoreError = (error) => {
  if (!error || !error.stack) {
    return true;
  }
  if (error.stack.indexOf('getReadMode') !== -1) {
    return true;
  }
  if (error.stack.indexOf('SITEKEY_RETRIEVAL_ATTEMPT_INTERVALS') !== -1) {
    return true;
  }
  if (error.stack.indexOf('Blocked a frame with origin') !== -1) {
    return true;
  }

  return false;
};

const sendErrorToAws = (conf, error) => {
  if (shouldIgnoreError(error)) {
    return;
  }
  getLatestSequenceToken(conf, cloudwatchlogs, latestStream =>
    sendErrorToLogs(error, conf, latestStream, cloudwatchlogs)
  );
};

export const initAWS = conf => {
  awsCreds = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: conf.identityPoolId
  });
  AWS.config = new AWS.Config({
    credentials: awsCreds,
    region: conf.region
  });
  cloudwatchlogs = new AWS.CloudWatchLogs({ apiVersion: '2014-03-28' });
  window.onerror = (message, source, lineno, colno, error) => {
    console.log('err', message, error);
    sendErrorToAws(conf, error || message);
  };
};

export const sendError = (conf, error) => {
  console.log('err', error);
  sendErrorToAws(conf, error);
};

