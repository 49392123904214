import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { t } from '../../../helpers';

export default class BookShuttleBus extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }

  initialState = {};

  constructor(props) {
    super(props);
    this.state = {
      focused: null,
      startDate: moment(),
      time: props.slice.primary.start_time || '10:00',
      radio: { value: props.slice.items.length ? props.slice.items[0].radio_button_value : '', error: false },
      bookingNumber: { value: '', error: false },
      name: { value: '', error: false },
      email: { value: '', error: false },
      hotel: { value: '', error: false },
      passengerCount: { value: 1, error: false },
      errored: false,
      status: null
    };
    this.initialState = { ...this.state };
    const startTime = props.slice.primary.start_time && props.slice.primary.start_time.split(':');
    const endTime = props.slice.primary.end_time && props.slice.primary.end_time.split(':');
    this.hourStart = startTime.length > 0 && parseInt(startTime[0]) || 10;
    this.minuteStart = startTime.length > 1 && parseInt(startTime[1]) || 0;
    this.hourEnd = endTime.length > 0 && parseInt(endTime[0]) || 23;
    this.minuteEnd = endTime.length > 1 && parseInt(endTime[1]) || 60;

    this.submit = this.submit.bind(this);
  }

  getTimeOptions() {
    const arr = [];
    for (let i = this.hourStart; i <= this.hourEnd; i++) {
      const h = i < 10 && '0' + i || i;
      if (i === this.hourEnd) {
        arr.push(<option key={'o-' + h + ':00'} value={h + ':00'}>{h + ':00'}</option>);
        break;
      }
      for (let j = this.minuteStart; j < this.minuteEnd; j += 15) {
        const m = j < 10 && '0' + j || j;
        const val = h + ':' + m;
        arr.push(<option key={'o-' + val} value={val}>{val}</option>);
      }
    }
    return arr;
  }

  validate() {
    const newState = this.state;
    let errored = false;
    if (!this.state.bookingNumber.value) {
      newState.bookingNumber = { ...newState.bookingNumber, error: true };
      errored = true;
    }
    if (!this.state.name.value) {
      newState.name = { ...newState.name, error: true };
      errored = true;
    }
    if (!this.state.email.value || !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email.value)) {
      newState.email = { ...newState.email, error: true };
      errored = true;
    }
    if (!this.state.hotel.value) {
      newState.hotel = { ...newState.hotel, error: true };
      errored = true;
    }
    if (!this.state.passengerCount.value || isNaN(this.state.passengerCount.value)) {
      newState.passengerCount = { ...newState.passengerCount, error: true };
      errored = true;
    }
    this.setState({ status: null, ...newState, errored });
    return !errored;
  }

  submit() {
    if (this.validate()) {
      const time = this.state.time.split(':');
      const date = this.state.startDate.hours(time[0]).minutes(time[1]).seconds(0);
      fetch(window.conf.reservationApi + '/hotel-delivery/' + this.state.bookingNumber.value, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          customerEmail: this.state.email.value,
          customerName: this.state.name.value,
          accommodationName: this.state.hotel.value,
          passengerCount: this.state.passengerCount.value,
          pickupTime: date.toISOString(),
          toEmail: this.props.slice.primary.email_recipient,
          comment: this.state.radio.value
        })
      }).then(res => {
        if (res.status > 302) {
          return Promise.reject(new Error());
        }
        return Promise.resolve();
      }).then(res => this.setState({ ...this.initialState, status: 'success' })
      ).catch(err => {
        this.setState({ status: 'error' });
      });
    }
  }
  render() {
    return (
      <section className="container contact-form">
        <h2>{RichText.asText(this.props.slice.primary.form_title)}</h2>
        <div className="form-row">
          <div className="col-12">
            {this.props.slice.items.map((item, i) => <div key={'opt_' + i} className="form-check">
              <input className="form-check-input" name="radios" type="radio" id={'radio_' + i}
                checked={this.state.radio.value === item.radio_button_value}
                value={item.radio_button_value}
                onChange={(evt) => this.setState({ radio: { value: evt.target.value } })}
              />
              <label className="form-check-label" htmlFor={'radio_' + i}>
                {item.radio_button_value}
              </label>
            </div>)}

          </div>
        </div>
        <div className="form-row mt-3">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="form-group">
              <label required htmlFor="booking_nr">{t('Booking reference number')}</label>
              <input className={'form-control' + (this.state.bookingNumber.error && ' is-invalid' || '')} id="booking_nr" type="text"
                value={this.state.bookingNumber.value} onChange={e => this.setState({ bookingNumber: { value: e.target.value } })} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="form-group">
              <label required htmlFor="name">{t('Your name')}</label>
              <input className={'form-control' + (this.state.name.error && ' is-invalid' || '')} id="name" type="text"
                value={this.state.name.value} onChange={e => this.setState({ name: { value: e.target.value } })} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="form-group">
              <label required htmlFor="email">{t('Your e-mail')}</label>
              <input className={'form-control' + (this.state.email.error && ' is-invalid' || '')} id="email" type="email"
                value={this.state.email.value} onChange={e => this.setState({ email: { value: e.target.value } })} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="form-group">
              <label required htmlFor="hotel_name">{t('Hotel name where you are staying')}</label>
              <input className={'form-control' + (this.state.hotel.error && ' is-invalid' || '')} id="hotel_name" type="text"
              value={this.state.hotel.value} onChange={e => this.setState({ hotel: { value: e.target.value } })} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-6 col-md-3 col-lg-3">
            <div className="form-group">
              <label required htmlFor="pickup_date_x">{t('Pickup time')}</label>
              <SingleDatePicker
                readOnly
                hideKeyboardShortcutsPanel
                noBorder
                block
                numberOfMonths={1}
                displayFormat="DD-MM-YYYY"
                date={this.state.startDate}
                onDateChange={date => {
                  this.setState({ startDate: date });
                }}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                id="pickup_date_x"
              />
            </div>
          </div>
          <div className="col-6 col-md-3 col-lg-2">
            <div className="form-group">
              <label></label>
              <select className={'form-control'} id="pickup_time"
                onChange={(evt) => this.setState({ time: evt.target.value })}>
                {this.getTimeOptions()}
              </select>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 col-md-6 col-lg-5">
            <div className="form-group">
              <label required htmlFor="nr_passengers">{t('Number of passengers')}</label>
              <input className={'form-control' + (this.state.passengerCount.error && ' is-invalid' || '')}
                id="nr_passengers" type="number" min={1}
              value={this.state.passengerCount.value} onChange={e => this.setState({ passengerCount: { value: e.target.value } })} />
            </div>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="col-12 col-md-6 col-lg-5">
            <button className="btn btn-info btn-lg btn-block" onClick={this.submit}>{t('Send request')}</button>
          </div>
        </div>
        {this.state.status === 'success' ?
        <div className="form-row">
          <div className="col-12">
            <p className="smaller mb-0 mt-1 text-success">{this.props.slice.primary.email_success_message}</p>
          </div>
        </div> : this.state.status === 'error' ?
        <div className="form-row">
          <div className="col-12">
            <p className="smaller mb-0 mt-1 text-danger">{this.props.slice.primary.email_error_message}</p>
          </div>
        </div> : null}
        <div className="form-row mt-3">
          <div className="col-12 col-lg-11">
            <p className="smaller mb-0">{this.props.slice.primary.additional_text_under_submit}</p>
          </div>
        </div>
      </section>
    );
  }
}
