import React from 'react';
import PropTypes from 'prop-types';
import NotFound from '../NotFound';
import { RichText } from 'prismic-reactjs';
import Header from '../layout/Header';
import { urlToLanguage, languageToUrl, langToPrismic, setLocale,
  htmlSerializer, initWidget, FETCH_LINKS_PAGE_TYPES } from '../../../helpers';
import Footer from '../layout/Footer';
import Helmet from 'react-helmet';
import Loader from '../Loader';
import CarInfoCard from '../slices/carinfo/CarInfoCard';
import { Modal } from 'react-bootstrap';

export default class PromoPage extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      notFound: false,
      modalItem: null,
      showModal: false
    };
    this.onLanguageChange = this.onLanguageChange.bind(this);
    if (this.props.prismicCtx) {
      this.fetchPage(this.props, 'promo_page');
    }
  }

  componentDidMount() {
    if (this.props.match.params.lang !== languageToUrl[window.conf.defaultLocale]) {
      setLocale(urlToLanguage[this.props.match.params.lang]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    if (
      !prevProps.prismicCtx ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.fetchPage(this.props, 'promo_page');
    }
  }

  fetchPage(props, type) {
    if (this.state.doc) {
      this.setState({ doc: null });
    }
    if (props.prismicCtx) {
      return props.prismicCtx.api.getByUID(
        type,
        props.match.params.uid,
        { lang: langToPrismic[urlToLanguage[props.match.params.lang]], fetchLinks: FETCH_LINKS_PAGE_TYPES },
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
            if (doc.data.enable_widget === 'true') {
              this.initWidget(doc);
            }
          } else {
            window.location.href = '/' + (urlToLanguage[props.match.params.lang] && (props.match.params.lang + '/') || '')+ '404';
          }
        }
      );
    }
    return null;
  }
  onLanguageChange(newLang) {
    const inNewLang = this.state.doc.alternate_languages.find(
      l => l.lang === langToPrismic[newLang]
    );
    setLocale(newLang);
    if (inNewLang) {
      this.props.history.push(
        '/' + languageToUrl[newLang] + '/' + inNewLang.uid
      );
    } else {
      this.props.history.push('/' + languageToUrl[newLang]);
    }
  }
  initWidget(doc) {
    const lang = urlToLanguage[this.props.match.params.lang];
    initWidget(lang, doc.data);
  }
  openBookingModal(car) {
    this.setState({
      showModal: true,
      modalItem: car
    });
  }
  render() {
    if (this.state.doc) {
      const meta = [
              { name: 'description', content: this.state.doc.data.meta_description },
              { property: 'og:title', content: this.state.doc.data.meta_title },
              { property: 'og:description', content: this.state.doc.data.meta_description },
              { property: 'og:type', content: 'website' },
              { property: 'og:url', content: window.location.href },
              { property: 'og:image', content: this.state.doc.data.background_image.url }
            ];
      const promoHeroStyle = {
                backgroundImage:
                  'url("' +
                  this.state.doc.data.background_image.url +
                  '")',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              };
      const promoHero = this.state.doc.data.enable_widget === 'true' ? <div className="promo-hero" style={promoHeroStyle}>
                <div className={`promo-hero-text ${this.state.doc.data.text_on_image_color}`}>
                  <RichText
                    render={this.state.doc.data.text_on_image}
                    linkResolver={this.props.prismicCtx.linkResolver}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
                <div
                  className="booking-widget-container"
                  id="booking-widget"
                ></div>
              </div> : null;
      return (
        <div className="content" data-wio-id={this.state.doc.id}>
          <Helmet
            title={this.state.doc.data.meta_title}
            meta={meta}
          />
          <Header {...this.props} onLanguageChange={this.onLanguageChange} />
          <main>
            <div className="container list-checkbox">
              <h1>{RichText.asText(this.state.doc.data.title)}</h1>
              {promoHero}
              <RichText
                render={this.state.doc.data.text}
                linkResolver={this.props.prismicCtx.linkResolver}
                htmlSerializer={htmlSerializer}
              />
              <div className="row">
                <div className={`car-info-card-container${this.state.doc.data.enable_widget !== 'true' && ' promo-page' || ''} mt-3`}>
                  {this.state.doc.data.car_list.map((c, i) =>
                    <CarInfoCard
                      key={'c_' + i}
                      item={c}
                      prismicCtx={this.props.prismicCtx}
                      onBookClick={this.state.doc.data.enable_widget === 'true' ? null : () => this.openBookingModal(c)}
                    />
                  )}
                </div>
              </div>
            </div>
          </main>
          <Footer {...this.props} />
          <BookingModal
            show={this.state.showModal}
            document={this.state.doc}
            lang={urlToLanguage[this.props.match.params.lang]}
            item={this.state.modalItem}
            onHide={() => this.setState({ showModal: false })}
          />
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return (
      <div className="content">
        <Header {...this.props} onLanguageChange={this.onLanguageChange} />
        <Loader />
        <Footer {...this.props} />
      </div>
    );
  }
}

class BookingModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    lang: PropTypes.string,
    document: PropTypes.object,
    item: PropTypes.object,
    onHide: PropTypes.func
  }
  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      initWidget(this.props.lang, this.props.document.data, 'booking-widget-modal', true, false);
    }
  }

  render() {
    return (
      <Modal show={this.props.show} size="lg" centered onHide={this.props.onHide}>
          <Modal.Header>
            <button className="close icon" onClick={this.props.onHide}>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="booking-widget-container" id="booking-widget-modal"></div>
          </Modal.Body>
      </Modal>
    );
  }
}

export { BookingModal };
