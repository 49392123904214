import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import Loader from '../Loader';
import { debounce } from 'lodash';
import { t } from '../../../helpers';

class TermsAndConditionsFiles extends Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedLang: props.slice.primary.default_language,
      numPages: null,
      containerWidth: null
    };
    this.onLangChange = this.onLangChange.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    this.documentContainer = React.createRef();
    this.resizeListener = debounce(this.resizeListener.bind(this), 100);
  }

  componentDidMount() {
    this.setState({ containerWidth: this.documentContainer.current.clientWidth });
    window.addEventListener('resize', this.resizeListener);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }
  resizeListener() {
    this.setState({ containerWidth: this.documentContainer.current.clientWidth });
  }
  onLangChange(evt) {
    this.setState({ selectedLang: evt.target.value, numPages: null });
  }
  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });
  }
  getPages() {
    const pages = [];
    if (this.state.numPages) {
      for (let i = 0; i < this.state.numPages; i++) {
        pages.push(<Page key={'p_' + i} pageNumber={i + 1} width={this.state.containerWidth} />);
      }
    }
    return pages;
  }

  render() {
    const item = this.props.slice.items.find(i => i.language === this.state.selectedLang);
    return (
      <section>
        <div className="tnc-files-container mt-4">
          <div className="form-group mb-0">
            <label htmlFor="lang">{t('Select language')}</label>
            <select className="form-control min-w" id="lang" value={this.state.selectedLang} onChange={this.onLangChange}>
              {this.props.slice.items.map((item, i) =>
                <option
                  key={'it_' + i}
                  value={item.language}
                >{item.language}</option>)}
            </select>
          </div>
          <a className="btn btn-lg btn-primary px-48" href={item.file_link.url} target="_blan">{t('Print')}</a>
        </div>
        {item &&
        <div className="tnc-files-content" ref={this.documentContainer}>
          <Document
            file={item.file_link.url}
            onLoadSuccess={this.onDocumentLoadSuccess}
            loading={<Loader />}
          >
            {this.getPages()}
          </Document>
        </div>
        }
      </section>

    );
  }
}

export default TermsAndConditionsFiles;
