import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'prismic-reactjs';
// import { htmlSerializer } from '../../../helpers';
import { NavLink } from 'react-router-dom';

export default class PromoOffersBlock extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }
  render() {
    return (
      <section>
        <div className="container promo-block-container">
        <div className="row">
        {this.props.slice.items.map((item, i) =>
          (<div
            key={'col_' + i}
            className="col-12 col-md-6"
          >
            <NavLink className="no-style" to={Link.url(item.link_to_promo_page, this.props.prismicCtx.linkResolver)}>
            <div
              className="promo-block"
              style={{
                background: 'url(' + item.background_image.url + ') no-repeat center',
                backgroundSize: 'cover'
              }}>
              <div className="promo-block-label">{item.block_label}</div>
              <div className="promo-block-text">
                <div className="promo-block-title">{item.block_title}</div>
                <div className="promo-block-description">{item.block_description}</div>
              </div>
            </div>
            </NavLink>
          </div>))}
        </div>
        </div>
      </section>
    );
  }
}
