import React from 'react';
import PropTypes from 'prop-types';

export default class PricingTableCheckboxes extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object,
    className: PropTypes.any
  }

  constructor(props) {
    super(props);
    this.state = {
      showTooltipModal: false,
      tooltipContent: null
    };
  }

  getTableRow(item, index) {
    const columns = [];
    for (let i = 0; i < this.props.slice.primary.column_count; i++) {
      columns.push(this.getTableColumn(item, index, i));
    }
    return <tr key={'item_' + index}>
      <td className="text-left">{item.row_title}</td>
      {columns}
    </tr>;
  }
  getTableColumn(item, rowIndex, i) {
        return (
      <td key={'r_' + rowIndex + '_col_' + i}>
        <span className={'icon icon-' + (item['column_' + (i + 1)] === 'ENABLED' ? 'checkbox' : 'none') + ' icon-lg'}></span>
      </td>
    );
  }
  getTableHead() {
    const cells = [];
    for (let i = 0; i < this.props.slice.primary.column_count; i++) {
      const tooltipContent = this.props.slice.primary['column_' + (i + 1) + '_tooltip'];
      const tooltip = tooltipContent && tooltipContent.url &&
        <span
          className="ml-1 icon icon-questionmark"
          onClick={() => this.setState({ showTooltipModal: true, tooltipContent: tooltipContent.url })}
        ></span> || null;
      cells.push(<td className="text-center" key={'h_' + i}>
        <h4>{this.props.slice.primary['column_' + (i+1) + '_title']}{tooltip}</h4>
        <span className="text-muted">{this.props.slice.primary['column_' + (i+1) + '_description']}</span>
      </td>);
    }
    return cells;
  }
  render() {
    return (
      <section>
        <div className={'container pricing-table-container ' + (this.props.className || '')}>
        <div className="row">
          <div className="col-12 text-center">
            <div className="table-container">
              <table className="table table-striped">
                <thead className="text-left">
                  <tr>
                    <td></td>
                    {this.getTableHead()}
                  </tr>
                </thead>
                <tbody>
                  {this.props.slice.items.map((item, i) => this.getTableRow(item, i))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>
        <TooltipModal
          show={this.state.showTooltipModal}
          onClose={() => this.setState({ showTooltipModal: false })}
          url={this.state.tooltipContent}
        />
      </section>
    );
  }
}

import { Modal } from 'react-bootstrap';

class TooltipModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    url: PropTypes.string
  }
  render() {
    return (
      <>
        <Modal size="xl" show={this.props.show} onHide={this.props.onClose}>
          <Modal.Header>
            <button className="close icon" onClick={this.props.onClose}>
            </button>
          </Modal.Header>
          <Modal.Body>{this.props.url && <img className="img-responsive" src={this.props.url}></img>}</Modal.Body>
        </Modal>
      </>
    );
  }
}
