import React from 'react';
import PropTypes from 'prop-types';

import { languageToUrl } from '../../helpers';

import Header from './layout/Header';
import Footer from './layout/Footer';

export default class NotFound extends React.Component {
    static propTypes = {
      prismicCtx: PropTypes.object,
      location: PropTypes.object,
      match: PropTypes.object,
      history: PropTypes.object
    };

    onLanguageChange(newLang) {
        this.props.history.push('/' + languageToUrl[newLang] + '/404');
    }

    render() {
        console.log(window.location);
        return (
            <div className="content">
                <Header {...this.props} onLanguageChange={this.onLanguageChange} />
                <main>
                    <div className="container">
                        <h1>404</h1>
                        <h2>Page not found</h2>
                        <button className="btn btn-lg btn-primary" onClick={() => this.props.history.replace('/')}>To home page</button>
                    </div>
                </main>
                <Footer {...this.props} />
            </div>
        );
    }
}
