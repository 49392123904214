const languageToUrl = {
  'en-gb': 'en',
  'de-de': 'de',
  'is-is': 'is',
  'ru-ru': 'ru',
  'ro-ro': 'ro'
};
const urlToLanguage = {
  'en': 'en-gb',
  'de': 'de-de',
  'is': 'is-is',
  'ru': 'ru-ru',
  'ro': 'ro-ro'
};

const langAsReadable = {
  'en-gb': 'English',
  'de-de': 'Deutsch',
  'is-is': 'Íslenska',
  'ru-ru': 'Русский',
  'ro-ro': 'Romanian'
};

const langToPrismic = {
  'en-gb': 'en-gb',
  'is-is': 'is',
  'de-de': 'de',
  'ru-ru': 'ru',
  'ro-ro': 'ro'
};
const prismicToLang = {
  'is': 'is-is',
  'en-gb': 'en-gb',
  'de': 'de-de',
  'ru': 'ru-ru',
  'ro': 'ro-ro'
};

export {
  languageToUrl,
  urlToLanguage,
  langAsReadable,
  langToPrismic,
  prismicToLang
};
