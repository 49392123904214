import React from 'react';
import ReactDOM from 'react-dom';
import PrismicApp from './app/PrismicApp';
import './index.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

ReactDOM.render(
  <PrismicApp />,
  document.getElementById('root'),
);
