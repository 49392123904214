import React from 'react';
import PropTypes from 'prop-types';
import NotFound from '../NotFound';
import { RichText, Link } from 'prismic-reactjs';
import Header from '../layout/Header';
import { urlToLanguage, languageToUrl, langToPrismic, setLocale, t, getQueryParam, FETCH_LINKS_PAGE_TYPES } from '../../../helpers';
import Footer from '../layout/Footer';
import Helmet from 'react-helmet';
import Loader from '../Loader';
import Prismic from 'prismic-javascript';
import { Link as RouterLink } from 'react-router-dom';

export default class BlogPage extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
  };
  constructor(props) {
    super(props);
    const cat = getQueryParam('category');
    this.state = {
      doc: null,
      posts: [],
      filteredPosts: [],
      categories: [],
      activeCategory: cat || '',
      notFound: false,
      itemsOnPage: 12,
      totalPagesVisible: 3,
      page: 0
    };
    this.onLanguageChange = this.onLanguageChange.bind(this);
    if (this.props.prismicCtx) {
      this.fetchPage(this.props, 'blog_page');
    }
  }

  componentDidMount() {
    if (this.props.match.params.lang !== languageToUrl[window.conf.defaultLocale]) {
      setLocale(urlToLanguage[this.props.match.params.lang]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    if (
      !prevProps.prismicCtx ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.fetchPage(this.props, 'blog_page');
    }
  }

  fetchPage(props, type) {
    if (this.state.doc) {
      this.setState({ doc: null });
    }
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle(
        type,
        { lang: langToPrismic[urlToLanguage[props.match.params.lang]], fetchLinks: FETCH_LINKS_PAGE_TYPES },
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
            this.fetchPosts();
          } else {
            window.location.href = '/' + (urlToLanguage[props.match.params.lang] && (props.match.params.lang + '/') || '') + '404';
          }
        }
      );
    }
    return null;
  }
  fetchPosts() {
    this.props.prismicCtx.api.query(
      Prismic.Predicates.at('document.type', 'blog_post'),
      {
        lang: langToPrismic[urlToLanguage[this.props.match.params.lang]],
        pageSize: 100, page: 1, orderings: '[document.first_publication_date desc]'
      },
      (err, res) => {
        if (res) {
          this.setState({
            posts: res.results,
            filteredPosts: this.state.activeCategory ?
              res.results.filter(p => p.tags.indexOf(this.state.activeCategory) !== -1) :
              res.results
          });
          this.assignTags(res.results);
        }
      }
    );
  }

  assignTags(results) {
    const tags = [];
    results.forEach(r => {
      for (const tag of r.tags) {
        if (tags.indexOf(tag) === -1) {
          tags.push(tag);
        }
      }
    });
    this.setState({ categories: tags });
  }

  getPostsLayout() {
    const first = this.state.page * this.state.itemsOnPage;
    const postsOnPage = this.state.filteredPosts.slice(first, first + this.state.itemsOnPage);
    return postsOnPage.map((post) => (
      <div key={post.uid} className="blog-post-card">
        <RouterLink to={Link.url(
               { ...post, link_type: 'Document' },
                this.props.prismicCtx.linkResolver
              )}>
        <div className="blog-post-card-image">
          <img src={post.data.thumbnail_image.thumb.url} alt={RichText.asText(post.data.title)} />
        </div>
        <div className="blog-post-card-title">
          {RichText.asText(post.data.title)}
        </div></RouterLink>
      </div>
    ));
  }

  changePage(page) {
    this.setState({ page });
  }

  getPaginator() {
    if (this.state.filteredPosts.length <= this.state.itemsOnPage) {
      return null;
    }
    const pages = [];
    for (let i = 0; i < Math.ceil(this.state.filteredPosts.length / this.state.itemsOnPage); i++) {
      pages.push(i);
    }
    return (
      <div className="blog-paginator">
        <button
          disabled={this.state.page === 0}
          className="btn btn-outline-primary prev-btn"
          onClick={() => this.changePage(this.state.page - 1)}
        ></button>
          {pages.map(p =>
            (p >= this.state.page + this.state.totalPagesVisible || p <= this.state.page - this.state.totalPagesVisible) ?
            null : (
            <button
              key={'k'+p}
              onClick={() => this.changePage(p)}
              className={`btn btn-outline-primary btn-paginator ${p === this.state.page ? 'active' : ''}`}
            >{p + 1}</button>
          ))}
        <button
          disabled={this.state.page === pages.length - 1}
          className="btn btn-outline-primary next-btn"
          onClick={() => this.changePage(this.state.page + 1)}
        ></button>
      </div>
    );
  }

  filterByCategory(category) {
    if (!category) {
      this.setState({ activeCategory: '', filteredPosts: this.state.posts, page: 0 });
      this.props.history.push('?');
    } else {
      this.setState({ activeCategory: category, filteredPosts: this.state.posts.filter(p => p.tags.indexOf(category) !== -1), page: 0 });
      this.props.history.push('?category=' + encodeURIComponent(category));
    }
  }
  onLanguageChange(newLang) {
    const inNewLang = this.state.doc.alternate_languages.find(
      l => l.lang === langToPrismic[newLang]
    );
    setLocale(newLang);
    if (inNewLang) {
      this.props.history.push(
        '/' + languageToUrl[newLang] + '/' + inNewLang.uid
      );
    } else {
      this.props.history.push('/' + languageToUrl[newLang]);
    }
  }

  render() {
    if (this.state.doc) {
      const meta = [
              { name: 'description', content: this.state.doc.data.meta_description },
              { property: 'og:title', content: this.state.doc.data.meta_title },
              { property: 'og:description', content: this.state.doc.data.meta_description },
              { property: 'og:type', content: 'website' },
              { property: 'og:url', content: window.location.href },
              { property: 'og:image', content: window.location.origin + '/logo_fb.png' }
            ];
      return (
        <div className="content" data-wio-id={this.state.doc.id}>
          <Helmet
            title={this.state.doc.data.meta_title}
            meta={meta}
          />
          <Header {...this.props} onLanguageChange={this.onLanguageChange} />
          <main>
            <div className="container">
              <h1>{RichText.asText(this.state.doc.data.title)}</h1>
              <div className="categories-dropdown form-group d-flex d-md-none">
                <select
                  className="form-control min-w"
                  id="selc"
                  value={this.state.activeCategory}
                  onChange={(e) => this.filterByCategory(e.target.value)}
                >
                  <option value={''}>{t('All categories')}</option>
                  {
                    this.state.categories.map(c =>
                    <option
                      key={c}
                      value={c}
                    >{c}</option>)
                  }
                </select>
              </div>
              <div className="categories-container d-none d-md-flex">
                <div
                  className={`category-link ${!this.state.activeCategory ? 'active' : ''}`}
                  onClick={() => this.filterByCategory()}
                >{t('All categories')}</div>
                {
                  this.state.categories.map(c => (
                    <div
                      className={`category-link ${this.state.activeCategory === c ? 'active' : ''}`}
                      key={c}
                      onClick={() => this.filterByCategory(c)}
                    >{c}</div>
                  ))
                }
              </div>
              <div className="blog-posts-container">
                {this.getPostsLayout()}
              </div>
              {this.getPaginator()}
            </div>
          </main>
          <Footer {...this.props} />
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return <div className="content">
      <Header {...this.props} onLanguageChange={this.onLanguageChange} />
      <Loader />
      <Footer {...this.props} />
    </div>;
  }
}
