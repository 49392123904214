import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../../helpers';

export default class TextSection extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }
  render() {
    return (
      <section className="container">
        <RichText
          render={this.props.slice.primary.text}
          linkResolver={this.props.prismicCtx.linkResolver}
          htmlSerializer={htmlSerializer}
        />
      </section>
    );
  }
}
