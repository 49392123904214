import React from 'react';
import PropTypes from 'prop-types';
import { Link, RichText } from 'prismic-reactjs';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { urlToLanguage, langToPrismic, t, FETCH_LINKS_PAGE_TYPES } from '../../../helpers';
import moment from 'moment';

export default class Header extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    onLanguageChange: PropTypes.func,
    onDocumentLoad: PropTypes.func
  };
  mounted = false;

  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      notFound: false,
      open: false,
      openedTabs: {}
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    if (
      !prevProps.prismicCtx ||
      prevProps.match.params.lang !== this.props.match.params.lang
    ) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle(
        'navigation',
        { lang: langToPrismic[urlToLanguage[props.match.params.lang]], fetchLinks: FETCH_LINKS_PAGE_TYPES },
        (err, doc) => {
          if (this.mounted) {
            if (doc) {
              this.setState({ doc });
              if (this.props.onDocumentLoad) {
                this.props.onDocumentLoad(doc);
              }
            } else {
              this.setState({ notFound: !doc });
            }
          }
        }
      );
    }
    return null;
  }

  menuLinks() {
    const visibleMapped = this.state.doc.data.nav.map((menuLink, i) => (
      <li
        key={menuLink.primary.link.id || 'id-' + i}
        className={
          'd-none d-md-flex ' + (this.isCurrentItem(menuLink) ? 'active' : '')
        }
      >
        <NavLink
          to={Link.url(
            menuLink.primary.link,
            this.props.prismicCtx.linkResolver
          )}
        >
          {RichText.asText(menuLink.primary.label)}
        </NavLink>
      </li>
    ));

    return visibleMapped;
  }
  isCurrentItem(link) {
    const uid = (this.props.match.url.indexOf('/blog') !== -1 && 'blog') || this.props.match.params.uid;
    const isBlogPage = uid === 'blog' && link.primary.link.type === 'blog_page';
    return (
      link.primary.link.uid && link.primary.link.uid === uid || isBlogPage ||
      !!link.items.find(i => i.sub_nav_link.uid && i.sub_nav_link.uid === uid)
    );
  }
  getMainMenuItem() {
    const mainItem = this.state.doc.data.nav.find(link =>
      this.isCurrentItem(link)
    );
    return mainItem;
  }
  isSubNavActive(item) {
    const uid =
      this.props.match.params.uid ||
      (this.props.match.url.indexOf('blog') !== -1 && 'blog');
    const isBlogPage =
      uid === 'blog' && item.sub_nav_link.type === 'blog_page';
    return (
      (this.props.match.params.uid &&
        item.sub_nav_link.uid === this.props.match.params.uid) || isBlogPage
    );
  }
  bottomNav() {
    const mainItem = this.getMainMenuItem();
    if (!mainItem || !mainItem.items.length) {
      return null;
    }
    const visibleList = mainItem.items.map(item => (
      <li
        key={item.sub_nav_link.id || '--'}
        className={this.isSubNavActive(item) ? 'active' : null}
      >
        <NavLink
          to={Link.url(item.sub_nav_link, this.props.prismicCtx.linkResolver)}
        >
          {RichText.asText(item.sub_nav_link_label)}
        </NavLink>
      </li>
    ));
    return (
      <nav className="d-none d-md-block sublinks">
        <ul className="nav-links">{visibleList}</ul>
      </nav>
    );
  }
  toggleMobileMenu() {
    document.body.className = !this.state.open ? 'modal-open' : '';
    this.setState({
      open: !this.state.open,
      openedTabs: this.state.open ? { ...this.state.openedTabs } : {}
    });
  }
  mobileMenu(langObject, query) {
    return (
      <div className="d-block d-md-none nav-mobile">
        <ul className="nav-mobile-ul">
          <li
            key={'id-home'}
            className={!this.props.match.params.uid ? 'active' : null}
          >
            <NavLink
              to={'/' + this.props.match.params.lang}
              onClick={this.toggleMobileMenu}
            >
              {this.state.doc.data.mobile_menu_home_link_text || 'Home'}
            </NavLink>
          </li>
          {this.state.doc.data.nav.map(menuLink => [
            <li
              key={menuLink.primary.link.id || 'id-'}
              className={
                (menuLink.items.length ? 'with-caret' : '') +
                (this.isCurrentItem(menuLink) ||
                this.state.openedTabs[menuLink.primary.link.id]
                  ? ' active'
                  : '')
              }
            >
              {!menuLink.items.length ? (
                <NavLink
                  to={Link.url(
                    menuLink.primary.link,
                    this.props.prismicCtx.linkResolver
                  )}
                  onClick={this.toggleMobileMenu}
                >
                  {RichText.asText(menuLink.primary.label)}
                </NavLink>
              ) : null}
              {menuLink.items.length ? (
                <a
                  role="button"
                  onClick={() =>
                    this.setState({
                      openedTabs: {
                        ...this.state.openedTabs,
                        [menuLink.primary.link.id]: !this.state.openedTabs[
                          menuLink.primary.link.id
                        ]
                      }
                    })
                  }
                >
                  {RichText.asText(menuLink.primary.label)}
                </a>
              ) : null}
            </li>,
            (this.isCurrentItem(menuLink) ||
              this.state.openedTabs[menuLink.primary.link.id]) &&
            menuLink.items.length
              ? [
                  ...menuLink.items.map((item, index) => (
                    <li
                      key={item.sub_nav_link.id || '--'}
                      className={
                        'submenu-link' +
                        (item.sub_nav_link.uid === this.props.match.params.uid
                          ? ' active'
                          : '') +
                        (index === menuLink.items.length - 1
                          ? ' last-element'
                          : '')
                      }
                    >
                      <NavLink
                        to={Link.url(
                          item.sub_nav_link,
                          this.props.prismicCtx.linkResolver
                        )}
                        onClick={this.toggleMobileMenu}
                      >
                        {RichText.asText(item.sub_nav_link_label)}
                      </NavLink>
                    </li>
                  )),
                  <li key="subm-sp" className="submenu-spacer" />
                ]
              : null
          ])}
        </ul>
        <div className="mobile-buttons-container">
          {(this.state.doc.data.book_a_car_link.link_type !== 'Web' && (
            <RouterLink
              className="btn btn-warning btn-lg mb-2 w-100"
              to={Link.url(
                this.state.doc.data.book_a_car_link,
                this.props.prismicCtx.linkResolver
              )}
            >
              {t('Book a car')}
            </RouterLink>
          )) || (
            <a
              className="btn btn-warning btn-lg mb-2 w-100"
              href={Link.url(
                this.state.doc.data.book_a_car_link,
                this.props.prismicCtx.linkResolver
              ) + query}
              target={this.state.doc.data.book_a_car_link.target}
            >
              {t('Book a car')}
            </a>
          )}
          <RouterLink
            className="btn btn-light btn-lg w-50 flex-0 mr-1"
            to={Link.url(
              this.state.doc.data.contact_us_link,
              this.props.prismicCtx.linkResolver
            )}
          >
            {t('Contact Us')}
          </RouterLink>
          <DropdownButton
            title={langObject && langObject.label}
            variant="light w-100"
            size="lg"
            className="w-50 flex-0 ml-1"
          >
            {this.state.doc.data.languages.map(lang => (
              <Dropdown.Item
                key={lang.language_code}
                onSelect={() => this.props.onLanguageChange(lang.language_code)}
                active={lang.language_code === langObject.language_code}
              >
                {lang.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      </div>
    );
  }

  getBookNowQuery(data) {
    let query = '/' + this.props.match.params.lang + '/prices?';
    const queryItems = [];
    if (data.default_pu_location) {
      queryItems.push('puLocation=' + data.default_pu_location.code);
      queryItems.push('doLocation=' + data.default_pu_location.code);
    }
    if (data.driver_age) {
      queryItems.push('driverAge=' + data.driver_age);
    }
    if (data.currency) {
      queryItems.push('currency=' + data.currency);
    }
    if (data.default_pu_date_shift && data.default_time && data.default_duration) {
      queryItems.push('puDate=' + moment().add(data.default_pu_date_shift, 'days')
          .hours(data.default_time.split(':')[0])
          .minutes(data.default_time.split(':')[1])
          .seconds(0).toISOString());
      queryItems.push('doDate=' + moment().add(data.default_pu_date_shift + data.default_duration, 'days')
          .hours(data.default_time.split(':')[0])
          .minutes(data.default_time.split(':')[1])
          .seconds(0).toISOString());
    }
    if (data.default_promo_code) {
      queryItems.push('promo=' + data.default_promo_code);
    }
    query += queryItems.join('&');

    return query;
  }

  render() {
    if (!this.props.prismicCtx) {
      return null;
    }
    if (this.state.doc) {
      const languages = this.state.doc.data.languages;
      let langObject = languages.find(
        l => l.language_code === urlToLanguage[this.props.match.params.lang]
      );
      langObject = langObject || { language_code: 'en-gb', label: 'ENG' };
      const query = this.getBookNowQuery(this.state.doc.data);
      return (
        <header
          className={'site-header' + ((this.state.open && ' open') || '')}
        >
          <nav className="top-nav">
            <div className="logo-container">
              <RouterLink to={'/' + this.props.match.params.lang}>
                <div className={'logo' + (this.state.open ? ' d-none' : '')} />
              </RouterLink>
            </div>
            <div className="nav-container">
              <ul className="nav-links">{this.menuLinks()}</ul>
            </div>
            <div className="buttons-container">
              <DropdownButton
                title={langObject.label}
                variant="link"
                size="lg"
                className="d-none d-md-block header-dropdown mr-2"
              >
                {languages.map(lang => (
                  <Dropdown.Item
                    key={lang.language_code}
                    onSelect={() =>
                      this.props.onLanguageChange(lang.language_code)
                    }
                    active={lang.language_code === langObject.language_code}
                  >
                    {lang.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              <RouterLink
                className="d-none d-md-inline-block btn btn-outline-light btn-lg ml-1 mr-1"
                to={Link.url(
                  this.state.doc.data.contact_us_link,
                  this.props.prismicCtx.linkResolver
                )}
              >
                {t('Contact Us')}
              </RouterLink>
              {(this.state.doc.data.book_a_car_link.link_type !== 'Web' && (
                <RouterLink
                  className="d-none d-md-inline-block btn btn-warning btn-lg ml-2"
                  to={Link.url(
                    this.state.doc.data.book_a_car_link,
                    this.props.prismicCtx.linkResolver
                  )}
                >
                  {t('Book a car')}
                </RouterLink>
              )) || (
                <a
                  className="d-none d-md-inline-block btn btn-warning btn-lg ml-2"
                  href={Link.url(
                    this.state.doc.data.book_a_car_link,
                    this.props.prismicCtx.linkResolver
                  ) + query}
                  target={this.state.doc.data.book_a_car_link.target}
                >
                  {t('Book a car')}
                </a>
              )}
              <div
                className={
                  'd-md-none burger-icon-container' +
                  ((this.state.open && ' open') || '')
                }
                onClick={this.toggleMobileMenu}
              >
                <div
                  className={
                    'd-md-none burger-icon' +
                    ((this.state.open && ' open') || '')
                  }
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
              </div>
            </div>
          </nav>
          {this.bottomNav()}
          {this.state.open && this.mobileMenu(langObject, query)}
        </header>
      );
    }
    return null;
  }
}
