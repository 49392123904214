import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Review = ({ item }) => {
  const date = moment(item.review_date).format('MMMM D [at] h:mma');
  return (
  <div className="review">
    <p>{item.review}</p>
    <div className="bottom-row">
      <div className="rating icon icon-star">{item.rating}/10</div>
      <div className="reviewer-container">
        <div className="reviewer">{item.reviewer}</div>
        <div className="date">{date}</div>
      </div>
    </div>
  </div>
  );
};

Review.propTypes = {
  item: PropTypes.shape({
    review: PropTypes.string,
    reviewer: PropTypes.string,
    review_date: PropTypes.string,
    rating: PropTypes.string
  })
};

export default Review;
