import React from 'react';
import 'whatwg-fetch';
import Prismic from 'prismic-javascript';
import PrismicConfig from '../prismic-configuration';
import App from './App';
import { addScriptUrlToHead, loadCookieConsentSettings, initAWS } from '../helpers';
import { sendError } from '../helpers/aws-logger';

export default class PrismicApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { prismicCtx: null, error: false };
    if (window.conf.awsLogs && window.conf.awsLogs.enabled) {
      console.log('AWS logging turned on');
      initAWS(window.conf.awsLogs);
    }
    addScriptUrlToHead(window.conf.cookieConsentLibraryUrl, null).then(() => loadCookieConsentSettings());
    this.buildContext().then((prismicCtx) => {
      this.setState({ prismicCtx });
    }).catch((e) => {
      console.error(`Cannot contact the API, check your prismic configuration:\n${e}`);
    });
  }

  refreshToolbar() {
    if (!window.PrismicToolbar) {
      return;
    }
    window.PrismicToolbar.setup(PrismicConfig.apiEndpoint);
  }

  buildContext() {
    const accessToken = PrismicConfig.accessToken;
    return Prismic.api(PrismicConfig.apiEndpoint, { accessToken }).then(api => ({
      api,
      endpoint: PrismicConfig.apiEndpoint,
      accessToken,
      linkResolver: PrismicConfig.linkResolver,
      toolbar: this.refreshToolbar,
    }));
  }
  componentDidCatch(error) {
    if (window.conf.awsLogs && window.conf.awsLogs.enabled) {
      sendError(window.conf.awsLogs, error);
    }
    const root = document.getElementById('root');
    if (!root || !root.childElementCount) {
      this.setState({ error: true });
    }
  }
  navigateBack() {
    window.location.href = window.location.origin + '/' + window.location.pathname.split('/')[1];
  }
  render() {
    if (this.state.error) {
      return (
        <div
          className="w-100 d-flex align-items-center justify-content-center flex-column background-primary"
          style={{ minHeight: '100vh' }}
        >
          <p className="mb-1">Website has run into an error!</p>
          <button className="btn btn-link" onClick={() => this.navigateBack()}>Click here to go to homepage</button>
        </div>
      );
    }
    return <App prismicCtx={this.state.prismicCtx} />;
  }
}
