import { languageToUrl, prismicToLang } from './helpers/language';
export default {

  apiEndpoint: window.conf.prismicUrl,

  // -- Access token if the Master is not open
  // accessToken: 'xxxxxx',

  // OAuth
  // clientId: 'xxxxxx',
  // clientSecret: 'xxxxxx',

  // -- Links resolution rules
  // This function will be used to generate links to Prismic.io documents
  // As your project grows, you should update this function according to your routes
  linkResolver(doc) {
    if (doc.type === 'page' || doc.type === 'pricing_page') return `/${languageToUrl[prismicToLang[doc.lang]]}/${doc.uid}`;
    if (doc.type === 'car_info_page' || doc.type === 'location_page' || doc.type === 'terms_and_conditions_subpage') {
      let href = window.location.href;
      if (href[href.length - 1] === '/') {
        href = href.substring(0, href.length - 1);
      }
      const parts = href.split('/');
      const url = doc.data && doc.data.parent ? doc.data.parent.uid : parts[parts.length - 1];
      return `/${languageToUrl[prismicToLang[doc.lang]]}/${url}/${doc.uid}`;
    }
    if (doc.type === 'contact_page') {
      return `/${languageToUrl[prismicToLang[doc.lang]]}/contact`;
    }
    if (doc.type === 'blog_page') {
      return `/${languageToUrl[prismicToLang[doc.lang]]}/blog`;
    }
    if (doc.type === 'blog_post') {
      return `/${languageToUrl[prismicToLang[doc.lang]]}/blog/${doc.uid}`;
    }
    if (doc.type === 'promo_page') {
      return `/${languageToUrl[prismicToLang[doc.lang]]}/promo/${doc.uid}`;
    }
    return `/${languageToUrl[prismicToLang[doc.lang]]}`;
  },
};
