import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'prismic-reactjs';

const HorizontalReview = ({ item, prismicCtx }) => {
  const image = item.image.url ?
    <div className="rating-image">
      <img src={item.image.url} />
    </div> : null;
  const rating = item.rating ?
    <div className="rating">
      <span className="rating-value">{item.rating}</span>
      <Rating
        readonly
        initialRating={item.rating}
        emptySymbol="icon icon-star-empty icon-xl"
        fullSymbol="icon icon-star-yellow icon-xl"
      />
    </div> : null;
  const link = item.link.link_type === 'Web' ?
    <a href={item.link.url} target={item.link.target}>{item.link_text}</a> :
    <RouterLink to={Link.url(
               item.link,
                prismicCtx.linkResolver
          )}
        >{item.link_text}</RouterLink>;
  return (
  <div className="horizontal-review">
    {rating}
    {image}
    <div className="bottom-row">
      <p className="mb-0">
        {link}
      </p>
    </div>
  </div>
  );
};

HorizontalReview.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.object,
    link: PropTypes.object,
    link_text: PropTypes.string,
    rating: PropTypes.string
  }),
  prismicCtx: PropTypes.object
};

export default HorizontalReview;
