import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { urlToLanguage, langToPrismic, htmlSerializer, FETCH_LINKS_PAGE_TYPES } from '../../../helpers';

export default class Footer extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    onLanguageChange: PropTypes.func
  };
  mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      notFound: false
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    if (!prevProps.prismicCtx || prevProps.match.params.lang !== this.props.match.params.lang) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle('navigation',
      { lang: langToPrismic[urlToLanguage[props.match.params.lang]], fetchLinks: FETCH_LINKS_PAGE_TYPES }, (err, doc) => {
        if (this.mounted) {
          if (doc) {
            this.setState({ doc });
          } else {
            this.setState({ notFound: !doc });
          }
        }
      });
    }
    return null;
  }

  getSocialMediaBar() {
    if (this.state.doc.data.social_media_bar_enabled !== 'true') {
      return null;
    }
    const data = this.state.doc.data;
    return <div className="social-media-bar-background">
      <div className="container social-media-bar">
        <div className="association-col">
            {data.association_logo.url && <img src={data.association_logo.url} /> || null}
            {data.association_description && <span>{data.association_description}</span> || null}
        </div>
        <div className="social-media-col">
            {data.social_media_items.map((item, i) =>
            <a key={'soc_' + i} href={item.social_media_link.url} target={item.social_media_link.target}>
              <div className="social-media-item">
                <img src={item.social_media_logo.url} />
                {item.social_media_link_text}
              </div></a>
            )}
        </div>
      </div>
    </div>;
  }

  render() {
    if (!this.props.prismicCtx) {
      return null;
    }
    if (this.state.doc) {
      return (
        <footer>
          <div className="top-section container">
            <div className="row">
            {this.state.doc.data.columns.map((item, i) => {
              const rendered =
              <RichText
                render={item.column_content}
                linkResolver={this.props.prismicCtx.linkResolver}
                htmlSerializer={htmlSerializer}
              />;
              return (
                <div key={i + '_k'} className="col-12 col-md-6">
                  {rendered}
                </div>);
              })
            }
            </div>
          </div>
          {this.getSocialMediaBar()}
          <div id="bottom-section" className="bottom-section">
            {<RichText render={this.state.doc.data.footer_text} linkResolver={this.props.prismicCtx.linkResolver} />}
          </div>
        </footer>
      );
    }
    return null;
  }
}
