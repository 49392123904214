import React from 'react';
import { Link } from 'react-router-dom';
import prismic from '../prismic-configuration';

export default function htmlSerializer(type, element, content, children, key) {
  if (type === 'preformatted') {
    if (element.text.indexOf('phone:') !== -1) {
      const val = element.text.replace('phone:', '');
      return <span key={key} className="d-block icon icon-phone">{val}</span>;
    } else if (element.text.indexOf('email:') !== -1) {
      const val = element.text.replace('email:', '');
      return <span key={key} className="d-block icon icon-email">{val}</span>;
    }
  }
  if (type === 'hyperlink') {
    if (element.data.link_type === 'Document') {
      return <Link
        key={key}
        to={prismic.linkResolver(element.data)}
        >{content}
      </Link>;
    }
  }
  if (type === 'list-item') {
    if (element.spans.length && element.spans.find(s => s.type === 'hyperlink')) {
      const hr = element.spans.find(s => s.type === 'hyperlink');
      if (hr.start === 0 && hr.end === element.text.length) {
        return <li key={key} className="with-arrow">{children}</li>;
      }
    }
  }
  if (type === 'embed') {
    return <div key={key} className="videoWrapper" dangerouslySetInnerHTML={{ __html: element.oembed.html }}></div>;
  }
  if (type === 'image') {
    return <p key={key} className="block-img">
      <img src={element.url} alt={element.alt} />
      {element.alt ? <span>
        {element.alt}
      </span> : null}
    </p>;
  }
  return null;
}
