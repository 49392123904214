import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'prismic-reactjs';
import { Link as RouterLink } from 'react-router-dom';
// import { htmlSerializer } from '../../../helpers';

export default class ButtonSlice extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }
  render() {
    const btnPos = this.props.slice.primary.button_position;
    let btnSize = this.props.slice.primary.button_size;
    const justifyContent = btnPos === 'left' ? 'flex-start' : btnPos === 'right' ? 'flex-end' : btnPos;
    btnSize = btnSize === 'small' ? 'sm' : btnSize === 'medium' ? 'md' : btnSize === 'large' ? 'lg' : 'xl';
    console.log(this.props.slice.primary);
    const link = this.props.slice.primary.button_link.link_type === 'Web' ?
      <a
        className={'btn btn-outline-primary btn-link-arrow btn-' + btnSize}
        href={this.props.slice.primary.button_link.url}
        target="_blank"
        rel="noopener noreferrer"
      >{this.props.slice.primary.button_text}</a> :
      <RouterLink to={
          Link.url(this.props.slice.primary.button_link,
                this.props.prismicCtx.linkResolver
          )}
          className={'btn btn-outline-primary btn-link-arrow btn-' + btnSize}
        >{this.props.slice.primary.button_text}</RouterLink>;
    return (
      <section style={{ display: 'flex', justifyContent }}>
        {link}
      </section>
    );
  }
}
