import React from 'react';
import PropTypes from 'prop-types';
import Review from './reviews/Review';
import { RichText, Link } from 'prismic-reactjs';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

export default class Reviews extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      page: 1,
      pageSize: 5
    };
  }
  componentDidMount() {
    if (this.props.slice.primary.reviews_page && this.props.slice.primary.reviews_page.uid) {
      this.requestReviews();
    }
  }
  requestReviews() {
    const props = this.props;
    const pageUID = this.props.slice.primary.reviews_page.uid;
    if (window.location.href.indexOf(pageUID) !== -1) {
      return;
    }
    if (props.prismicCtx) {
      return props.prismicCtx.api.getByUID(
        'page',
        pageUID,
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
          }
        }
      );
    }
  }
  render() {
    let reviews = [...this.props.slice.items];
    reviews = reviews.sort((a, b) => moment(a.review_date).isBefore(b.review_date) ? 1 : -1);

    let hasMore = true;
    if (this.state.doc) {
      const reviewsSlice = this.state.doc.data.body.find(s => s.slice_type === 'reviews');
      if (reviewsSlice) {
        let items = [...reviewsSlice.items];
        items = items.sort((a, b) => moment(a.review_date).isBefore(b.review_date) ? 1 : -1);
        reviews = items.slice(0, 4);
      }
    } else {
        const end = this.state.page * this.state.pageSize;
        reviews = reviews.slice(0, end);
        hasMore = reviews.length < this.props.slice.items.length;
      }
    return (
      <section>
        <div className="container columns-container">
          <div className="text-center">
            <h2>{this.props.slice.primary.title && RichText.asText(this.props.slice.primary.title) || null}</h2>
          </div>
          {reviews && reviews.map((item, i) => <Review key={'col_' + i} item={item} />)}
          {this.props.slice.primary.reviews_page && this.props.slice.primary.reviews_page.uid &&
          <p className="text-center mt-4">
            <RouterLink
              className=""
              to={Link.url(
                this.props.slice.primary.reviews_page,
                this.props.prismicCtx.linkResolver
              )}
            >
              {this.props.slice.primary.link_to_reviews_text}
            </RouterLink>
          </p>
          || (hasMore &&
            <p className="text-center mt-4">
              <a role="button" tabIndex="-1" onClick={() => this.setState({ page: this.state.page + 1 })}>
                {this.props.slice.primary.load_more_text}</a>
            </p>)}
        </div>
      </section>
    );
  }
}
