import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Link } from 'prismic-reactjs';
import { vehicleTypes, t } from '../../../../helpers';

const removeTransType = (name) => name.replace(/(A|a)utomatic|(M|m)anual/, '');

const CarInfoCard = ({ item, prismicCtx, onBookClick }) => (
  <div className={`car-info-card-border${onBookClick ? ' with-btn' : ''}`}>
    <div className="border-container">
      <NavLink to={Link.url(item.link_to_car_info_page, prismicCtx.linkResolver)}>
        <div className="car-info-card">
          <div className="d-flex d-md-none car-info-card-body">
            <img src={item.thumbnail_override.url || item.car_info.pictureURL || item.car_info.imageUrl } />
          </div>
          <div className="car-info-card-content">
            <div className="car-info-card-header">
              <span className="car-model">{removeTransType(item.car_info.model)}</span>
              <span className="car-category">
                {vehicleTypes[item.car_info.categoryType]}
              </span>
            </div>
            <div className="d-none d-md-flex car-info-card-body">
              <img src={item.thumbnail_override.url || item.car_info.pictureURL || item.car_info.imageUrl } />
            </div>
            <div className="car-info-card-vehicledata">
              <span className="icon icon-wheel">
                {t(item.car_info.driveType !== 'N/A' && item.car_info.driveType || '2WD')}
              </span>
              <span className="icon icon-gearbox">
                {item.gearbox_override || item.car_info.transmissionType}
              </span>
              <span className="icon icon-person">
                {item.car_info.passengerCount}
              </span>
              <span className="icon icon-luggage">
                {item.car_info.baggageCount}
              </span>
            </div>
          </div>
        </div>
      </NavLink>
                {onBookClick ?
            <div className="car-info-card-book-btn">
              <button className="btn btn-lg btn-block btn-primary" onClick={onBookClick}>Get price and book</button>
            </div> : null}
    </div>
  </div>
);
CarInfoCard.propTypes = {
  item: PropTypes.shape({
    car_info: PropTypes.shape({
      acrissCode: PropTypes.string,
      airCon: PropTypes.bool,
      baggageCount: PropTypes.number,
      categoryType: PropTypes.string,
      doorCount: PropTypes.string,
      driveType: PropTypes.string,
      fuelType: PropTypes.string,
      pictureURL: PropTypes.string,
      imageUrl: PropTypes.string,
      model: PropTypes.string,
      passengerCount: PropTypes.string,
      transmissionType: PropTypes.string,
      vehicleType: PropTypes.string
    }),
    gearbox_override: PropTypes.string,
    link_to_car_info_page: PropTypes.object,
    thumbnail_override: PropTypes.object
  }),
  prismicCtx: PropTypes.object,
  onBookClick: PropTypes.func
};

export default CarInfoCard;
