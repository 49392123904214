import React from 'react';
import PropTypes from 'prop-types';
import TextSection from './TextSection';
import Columns from './Columns';
import CarInfo from './CarInfo';
import Reviews from './Reviews';
import FaqBlock from './FaqBlock';
import PricingTable from './pricing/PricingTable';
import PricingTableCheckboxes from './pricing/PricingTableCheckboxes';
import TermsAndConditionsFiles from './TermsAndConditionsFiles';
import BrimborgContactForm from './BrimborgContactForm';
import BookShuttleBus from './BookShuttleBus';
import ReviewsHorizontal from './ReviewsHorizontal';
import PromoOffersBlock from './PromoOffersBlock';
import ButtonSlice from './Button';
import ExtrasList from './ExtrasList';

export default class SliceZone extends React.Component {
  static propTypes = {
    sliceZone: PropTypes.array,
    prismicCtx: PropTypes.object

  }
  getSlices() {
    const array = [];
    let tables = [];
    let index = 0;
    for (const slice of this.props.sliceZone) {
      if (slice.slice_type.indexOf('pricing_table') !== -1) {
        tables.push(slice);
      } else {
        this.addTablesToArray(tables, array, index);
        tables = [];
        array.push(this.getSliceByType(slice, index));
      }
      index += 1;
    }
    this.addTablesToArray(tables, array, index);
    return array;
  }
  addTablesToArray(tables, array, index) {
    if (tables.length) {
      array.push(<div className="tables-container" key={'t-slice-' + index} >
        {tables.map((t, i) => t.slice_type === 'pricing_table' ?
          <PricingTable slice={t} key={'ta-slice-' + i} prismicCtx={this.props.prismicCtx}
          /> :
          <PricingTableCheckboxes slice={t} key={'ta-slice-' + i} prismicCtx={this.props.prismicCtx}
          />)}
      </div>);
    }
    return array;
  }
  getSliceByType(slice, index) {
    switch (slice.slice_type) {
      case ('text'):
        return <TextSection slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx}/>;
      case 'columns':
        return <Columns slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'car_class_list':
        return <CarInfo slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'reviews':
        return <Reviews slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'reviews_external':
        return <ReviewsHorizontal slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'faq_block':
        return <FaqBlock slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'terms_and_conditions_files':
        return <TermsAndConditionsFiles slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'brimborg_contact_form':
        return <BrimborgContactForm slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'book_shuttle_bus':
        return <BookShuttleBus slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'promo_offers_block':
        return <PromoOffersBlock slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'button':
        return <ButtonSlice slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      case 'extras_list':
        return <ExtrasList slice={slice} key={'slice-' + index} prismicCtx={this.props.prismicCtx} />;
      default:
        return null;
    }
  }
  render() {
    return this.getSlices();
  }
}
