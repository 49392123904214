import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { htmlSerializer } from '../../../helpers';
import SliceZone from '../slices/Slice';

export default class TermsAndConditionsPage extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    doc: PropTypes.object
  };

  render() {
    const doc = this.props.doc;
    return (
      <main>
        <div className="container">
          <h1>
            {RichText.asText(doc.data.title)}
          </h1>
          <RichText render={doc.data.description} linkResolver={this.props.prismicCtx.linkResolver} htmlSerializer={htmlSerializer} />
          <SliceZone
            sliceZone={doc.data.body}
            prismicCtx={this.props.prismicCtx}
          />
        </div>
      </main>
    );
  }
}
