import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

export default class ExtrasList extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }
  render() {
    return (
      <section className="container mt-5">
            {this.props.slice.items.map((item, i) =>
              <div className="row extras-list-item" key={i + '_'}>
                <div className="d-none d-sm-flex align-items-center col-12 col-sm-4 col-md-3">
                  <img src={item.image.url} />
                </div>
                <div className="col-12 col-sm-8 col-md-9">
                  <h2>{item.extra_title}</h2>
                  <img className="d-block d-sm-none" src={item.image.url} />
                  <RichText render={item.extra_price}></RichText>
                  <RichText render={item.extra_description}></RichText>
                </div>

              </div>
            )}
      </section>
    );
  }
}
