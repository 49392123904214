import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import MutationObserver from 'react-mutation-observer';
import { RichText } from 'prismic-reactjs';
import Loader from '../Loader';

export default class BrimborgContactForm extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }
  constructor(props) {
    super(props);

    this.removeStyle = this.removeStyle.bind(this);
  }
  removeStyle(evt) {
    if (evt.child.type === 'text/css') {
      evt.target.removeChild(evt.child);
    }
  }
  render() {
    window.loc = this.props.slice.primary.contact_form_location;
    return (
      <section className="container contact-form">
        <h2>{RichText.asText(this.props.slice.primary.form_title)}</h2>
        <Helmet>
          <script type="text/javascript" src={this.props.slice.primary.widget_script_url}></script>
        </Helmet>
        <MutationObserver onChildAddition={this.removeStyle}>
          <div id="widget" pageid={this.props.slice.primary.pageid}>
            <Loader />
          </div>
        </MutationObserver>
      </section>
    );
  }
}
