import React from 'react';
import PropTypes from 'prop-types';
import { RichText, Link } from 'prismic-reactjs';
import { Link as RouterLink } from 'react-router-dom';

export default class PricingTable extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object,
    className: PropTypes.any
  }

  constructor(props) {
    super(props);
    this.state = {
      showTooltipModal: false,
      tooltipContent: null
    };
  }

  getTableRow(item, index) {
    const columns = [];
    for (let i = 0; i < this.props.slice.primary.column_count; i++) {
      columns.push(this.getTableColumn(item, index, i));
    }
    return <tr key={'item_' + index} className={item.row_type === 'HEADER' && 'header' || ''}>
      {columns}
    </tr>;
  }
  getTableColumn(item, rowIndex, i) {
    const tooltipContent = item['column_' + (i + 1) + '_tooltip_content'];
    const tooltip = tooltipContent && tooltipContent.url &&
      <span
        className="ml-1 icon icon-questionmark"
        onClick={() => this.setState({ showTooltipModal: true, tooltipContent: tooltipContent.url })}
      ></span> || null;
    return (
      <td key={'r_' + rowIndex + '_col_' + i}
        className={'text-left ' + (item['column_' + (i + 1) + '_highlighted'] === 'YES' && 'highlight' || '')}
      >
        {item['column_' + (i + 1)]}{tooltip}
      </td>
    );
  }
  render() {
    return (
      <section>
        <div className={'container pricing-table-container ' + (this.props.className || '')}>
        <div className="row">
          <div className="col-12 text-center">
            <div className="table-container">
              <table className="table table-striped">
                {!!this.props.slice.primary.table_title.length && <thead className="text-left border-bottom">
                  <tr>
                    <td colSpan={this.props.slice.primary.column_count}>
                      <h3>{RichText.asText(this.props.slice.primary.table_title)}
                      <RouterLink
                        className="btn btn-link py-0"
                        to={Link.url(
                          this.props.slice.primary.link_in_table_header,
                          this.props.prismicCtx.linkResolver
                        )}
                      >
                        {this.props.slice.primary.link_text}
                      </RouterLink>
                      </h3>
                    </td>
                  </tr>
                </thead>}
                <tbody>
                  {this.props.slice.items.map((item, i) => this.getTableRow(item, i))}
                </tbody>
              </table>
            </div>

          </div>
        </div>
        </div>
        <TooltipModal
          show={this.state.showTooltipModal}
          onClose={() => this.setState({ showTooltipModal: false })}
          url={this.state.tooltipContent}
        />
      </section>
    );
  }
}

import { Modal } from 'react-bootstrap';

class TooltipModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    url: PropTypes.string
  }
  render() {
    return (
      <>
        <Modal size="xl" show={this.props.show} onHide={this.props.onClose}>
          <Modal.Header>
            <button className="close icon" onClick={this.props.onClose}>
            </button>
          </Modal.Header>
          <Modal.Body>{this.props.url && <img className="img-responsive" src={this.props.url}></img>}</Modal.Body>
        </Modal>
      </>
    );
  }
}
