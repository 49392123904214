import React from 'react';
import PropTypes from 'prop-types';
import NotFound from '../NotFound';
import { RichText } from 'prismic-reactjs';
import Header from '../layout/Header';
import { urlToLanguage, languageToUrl, langToPrismic, setLocale,
  htmlSerializer, initWidget, FETCH_LINKS_PAGE_TYPES } from '../../../helpers';
import Footer from '../layout/Footer';
import Helmet from 'react-helmet';
import Loader from '../Loader';

export default class PartnerPage extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.state = {
      doc: null,
      headerDoc: null,
      notFound: false,
      widgetInitiated: false
    };
    this.onLanguageChange = this.onLanguageChange.bind(this);
    this.onHeaderLoaded = this.onHeaderLoaded.bind(this);
    if (this.props.prismicCtx) {
      this.fetchPage(this.props, 'partner_page');
    }
  }

  componentDidMount() {
    if (this.props.match.params.lang !== languageToUrl[window.conf.defaultLocale]) {
      setLocale(urlToLanguage[this.props.match.params.lang]);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.prismicCtx) {
      this.props.prismicCtx.toolbar();
    }
    if (
      !prevProps.prismicCtx ||
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.fetchPage(this.props, 'partner_page');
    }
    if (this.state.doc && this.state.headerDoc && !this.state.widgetInitiated) {
      console.log(this.state.headerDoc.data);
      this.initWidget({ ...this.state.headerDoc.data, ...this.state.doc.data });
      this.setState({ widgetInitiated: true });
    }
  }

  fetchPage(props, type) {
    if (this.state.doc) {
      this.setState({ doc: null });
    }
    if (props.prismicCtx) {
      return props.prismicCtx.api.getSingle(
        type,
        { lang: langToPrismic[urlToLanguage[props.match.params.lang]], fetchLinks: FETCH_LINKS_PAGE_TYPES },
        (err, doc) => {
          if (doc) {
            this.setState({ doc });
          } else {
            this.props.history.push('/' + (urlToLanguage[props.match.params.lang] && (props.match.params.lang + '/') || '') + '404');
          }
        }
      );
    }
    return null;
  }
  onLanguageChange(newLang) {
    const inNewLang = this.state.doc.alternate_languages.find(
      l => l.lang === langToPrismic[newLang]
    );
    setLocale(newLang);
    if (inNewLang) {
      this.props.history.push(
        '/' + languageToUrl[newLang] + '/' + inNewLang.uid
      );
    } else {
      this.props.history.push('/' + languageToUrl[newLang]);
    }
  }
  initWidget(data) {
    const lang = urlToLanguage[this.props.match.params.lang];
    initWidget(lang, data);
  }
  onHeaderLoaded(doc) {
    this.setState({ headerDoc: doc });
  }
  render() {
    if (this.state.doc) {
      const meta = [
              { name: 'description', content: this.state.doc.data.meta_description },
              { property: 'og:title', content: this.state.doc.data.meta_title },
              { property: 'og:description', content: this.state.doc.data.meta_description },
              { property: 'og:type', content: 'website' },
              { property: 'og:url', content: window.location.href },
              { property: 'og:image', content: this.state.doc.data.background_image.url }
            ];
      const promoHeroStyle = {
                backgroundImage:
                  'url("' +
                  this.state.doc.data.background_image.url +
                  '")',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              };
      const promoHero = <div className="promo-hero partner-page" style={promoHeroStyle}>
                <div className={`promo-hero-text ${this.state.doc.data.text_on_image_color}`}>
                  <RichText
                    render={this.state.doc.data.text_on_image}
                    linkResolver={this.props.prismicCtx.linkResolver}
                    htmlSerializer={htmlSerializer}
                  />
                </div>
                <div
                  className="booking-widget-container"
                  id="booking-widget"
                ></div>
              </div>;
      return (
        <div className="content" data-wio-id={this.state.doc.id}>
          <Helmet
            title={this.state.doc.data.meta_title}
            meta={meta}
          />
          <Header {...this.props} onLanguageChange={this.onLanguageChange} onDocumentLoad={this.onHeaderLoaded} />
          <main>
            <div className="container list-checkbox">
              <h1>{RichText.asText(this.state.doc.data.title)}</h1>
              {promoHero}
              <RichText
                render={this.state.doc.data.text}
                linkResolver={this.props.prismicCtx.linkResolver}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </main>
          <Footer {...this.props} />
        </div>
      );
    } else if (this.state.notFound) {
      return <NotFound />;
    }
    return (
      <div className="content">
        <Header {...this.props} onLanguageChange={this.onLanguageChange} />
        <Loader />
        <Footer {...this.props} />
      </div>
    );
  }
}
