import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import Preview from './Preview';
import Page from './components/pages/Page';
import Subpage from './components/pages/Subpage';
import BlogPage from './components/pages/BlogPage';
import BlogPostPage from './components/pages/BlogPostPage';
import PromoPage from './components/pages/PromoPage';
import PartnerPage from './components/pages/PartnerPage';
import NotFound from './components/NotFound';
const defaultLang = 'en';

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const App = (props) => (
  <Router onUpdate={() => setTimeout(() => window.scrollTo(0, 0))}>
    <Route component={ScrollToTop} />
    <Switch>
      <Redirect exact from="/" to={'/' + defaultLang} />
      <Route exact path="/preview" render={routeProps => <Preview {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/:lang" render={routeProps => <HomePage {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/:lang/404" render={routeProps => <NotFound {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/:lang/partner" render={routeProps => <PartnerPage {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/:lang/blog" render={routeProps => <BlogPage {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/:lang/blog/:uid" render={routeProps => <BlogPostPage {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/:lang/promo/:uid" render={routeProps => <PromoPage {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/:lang/:uid" render={routeProps => <Page {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/:lang/:uid/:subpage" render={routeProps => <Subpage {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Redirect to={'/' + defaultLang + '/404'} />
    </Switch>
  </Router>

);

App.propTypes = {
  prismicCtx: PropTypes.object
};

export default App;
