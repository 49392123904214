import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';

export default class Reviews extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      hash: null
    };
  }

  handleUrlHash() {
    const hash = window.location.hash ? window.location.hash.substring(1) : null;
    if (hash && hash !== this.state.hash) {
      setTimeout(() => {
        const el = document.getElementById(hash);
        this.setState({ hash });
        if (el) {
          if (el.children[0].className.indexOf('show') === -1) {
            el.children[1].click();
          }
          el.scrollIntoView();
        }
      }, 100);
    }
    return hash;
  }

  render() {
    const hash = this.handleUrlHash();
    return (
      <section>
        <div className="container faq-container">
          <div className="row">
            <div className="col-12 text-center">
              <h2>{RichText.asText(this.props.slice.primary.block_title)}</h2>
            </div>
            <div className="col-12">
              <Accordion defaultActiveKey={hash}>
                {this.props.slice.items.map((item, i) => <FaqItem isDefault={hash === item.question_anchor} item={item} key={'fq_' + i}
                prismicCtx={this.props.prismicCtx} />)}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

class FaqItem extends React.Component {
  static propTypes = {
    item: PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.array,
      question_anchor: PropTypes.string
    }).isRequired,
    prismicCtx: PropTypes.object.isRequired,
    isDefault: PropTypes.bool
  }
  constructor(props) {
    super(props);
    this.state = {
      closing: !props.isDefault
    };
  }

  className = null;
  componentDidMount() {
    const e = document.getElementById('c_' + this.props.item.question_anchor);
    this.className = e.className;
    const observer = new MutationObserver((event) => {
      if (event[0].target.className === 'collapsing') {
        this.setState({ closing: this.className === 'collapse show' });
      }
      this.className = event[0].target.className;
    });

    observer.observe(e, {
      attributes: true,
      attributeFilter: ['class'],
      childList: true,
      characterData: false
    });
  }
  render() {
    return (
      <div className={'accordion-item' + (this.state.closing ? ' closing' : '')} id={this.props.item.question_anchor}>
      <Accordion.Collapse eventKey={this.props.item.question_anchor} id={'c_' + this.props.item.question_anchor}>
        <RichText render={this.props.item.answer} linkResolver={this.props.prismicCtx.linkResolver} />
      </Accordion.Collapse>
      <Accordion.Toggle as={Button} variant="link" eventKey={this.props.item.question_anchor}>
        <p className="m-0">{this.props.item.question}</p>
      </Accordion.Toggle>
      <div className="background"></div>
      </div>
    );
  }
}
