import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import { BookingModal } from './PromoPage';
import { vehicleTypes, t, htmlSerializer, urlToLanguage } from '../../../helpers';

export default class CarInfoPage extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    doc: PropTypes.object,
    headerDoc: PropTypes.object
  };
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
    this.openBookingModal = this.openBookingModal.bind(this);
  }
  openBookingModal() {
    this.setState({
      showModal: true
    });
  }
  render() {
    const doc = this.props.doc;
    const carInfo = doc.data.car_info;
    const headerDoc = this.props.headerDoc;
    const modalDoc = { data: doc.data };
    if (headerDoc) {
      modalDoc.data = { ...headerDoc.data, ...modalDoc.data };
    }
    if (!carInfo) {
      return (
        <main>
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              Error in prismic configuration. Please fill car_info field.
            </div>
          </div>
        </main>
      );
    }
    return (
      <main>
        <div className="container">
          <h1>
            {RichText.asText(doc.data.title)}
            <span className="text-muted ml-2 font-weight-normal">
              {t('or similar')}
            </span>
          </h1>
          <p className="car-category-title">
            {t('Car class')}{' '}
            <strong>{vehicleTypes[carInfo.categoryType]}</strong>
          </p>
          <RichText render={doc.data.description} linkResolver={this.props.prismicCtx.linkResolver} htmlSerializer={htmlSerializer} />
          <p className="block-img pb-48">
            <img className="img-responsive" src={doc.data.main_image && doc.data.main_image.url ||
              carInfo.pictureURL || carInfo.imageUrl } />
            {doc.data.main_image.alt ? <span>{doc.data.main_image.alt}</span> : null}
          </p>
          <div className="row pb-48">
            <div className="col-12 col-md-6">
              <table className="table table-striped table-lg">
                <tbody>
                  <tr>
                    <td>
                      <span className="icon icon-wheel icon-lg">
                        {carInfo.driveType === 'N/A'
                          ? t('2WD')
                          : t(carInfo.driveType)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="icon icon-gearbox icon-lg">
                        {doc.data.gearbox_override || (carInfo.transmissionType === 'M'
                          ? t('Manual')
                          : t('Automatic'))}
                      </span>
                    </td>
                  </tr>
                  {carInfo.airCon && (
                    <tr>
                      <td>
                        <span className="icon icon-snowflake icon-lg">
                          {t('Climate control')}
                        </span>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <span className="icon icon-person icon-lg">
                        {carInfo.passengerCount} {t('passengers')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="icon icon-luggage icon-lg">
                        {carInfo.baggageCount} {t('bags')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span className="icon icon-door icon-lg">
                        {carInfo.doorCount} {t('doors')}
                      </span>
                    </td>
                  </tr>
                  {carInfo.features && carInfo.features.map(feat => (
                    <tr key={feat}>
                      <td>
                        <span className={`icon icon-${feat} icon-lg`}>
                          {t('car-feature-' + feat)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {doc.data.enable_car_booking_button && <div className="col-12 col-md-6">
              <button className="btn btn-info btn-xl ml-auto mr-auto mr-md-0" onClick={this.openBookingModal}>
                {t('Book this type of car')}
              </button>
            </div>}
          </div>
          {doc.data.images.map((img, i) => (
            <p className="block-img" key={'img_' + i}>
              <img
                className="img-responsive"
                src={img.car_image.url}
                alt={img.car_image.alt}
              />
              {img.car_image.alt ?
                <span>
                  {img.car_image.alt}
                </span> : null
              }
            </p>
          ))}
        </div>
          <BookingModal
            show={this.state.showModal}
            document={modalDoc}
            lang={urlToLanguage[this.props.match.params.lang]}
            item={this.props.doc.data.car_info}
            onHide={() => this.setState({ showModal: false })}
          />
      </main>
    );
  }
}
