import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import HorizontalReview from './reviews/HorizontalReview';

export default class ReviewsHorizontal extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }
  render() {
    return (
      <section>
        <div className="container columns-container pb-0">
          <div className="text-center">
            <h2>{RichText.asText(this.props.slice.primary.title)}</h2>
          </div>
          <div className="d-flex justify-content-around flex-wrap mt-5">
            {this.props.slice.items.map((item, i) => <HorizontalReview key={'col_' + i} item={item} prismicCtx={this.props.prismicCtx} />)}
          </div>
        </div>
      </section>
    );
  }
}
