import React from 'react';
import PropTypes from 'prop-types';
import CarInfoCard from './carinfo/CarInfoCard';
import { RichText } from 'prismic-reactjs';

export default class CarInfo extends React.Component {
  static propTypes = {
    slice: PropTypes.object,
    prismicCtx: PropTypes.object
  }
  render() {
    return (
      <section className="container pb-48">
          <h2>{RichText.asText(this.props.slice.primary.block_title)}</h2>
        <div className="row">
          <div className="car-info-card-container">
            {this.props.slice.items.map((item, i) =>
              <CarInfoCard key={'col_' + i} item={item} prismicCtx={this.props.prismicCtx} />
            )}
          </div>
        </div>
      </section>
    );
  }
}
