import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import moment from 'moment';
import { t, htmlSerializer } from '../../../helpers';
import SliceZone from '../slices/Slice';

export default class LocationPage extends React.Component {
  static propTypes = {
    prismicCtx: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    doc: PropTypes.object
  };

  getTimesByType(object) {
    const type = object.open_type || object.open_Type;
    switch (type) {
      case 'CLOSED':
        return t('Closed');
      case 'OPEN_HOURS':
        return object.open_1 === '00:01' && object.close_1 === '23:59' ? t('Open 24 hours') : object.open_1 + '-' + object.close_1 +
          (object.open_2 ? ', ' + object.open_2 + '-' + object.close_2 : '') +
          (object.open_3 ? ', ' + object.open_3 + '-' + object.close_3 : '');
      case 'OPEN_24_H':
        return t('Open 24 hours');
      default:
        return '';
    }
  }

  getOpeningTimesGroup(item, key) {
    return (
            <div key={key} className="col-12 col-md-6">
              <h4><strong>{item.opening_times_title}</strong></h4>
              <table className="table table-striped">
                {item.opening_times.openings ?
                <tbody>
                  <tr>
                    <td>{t('Monday')}</td><td>{this.getTimesByType(item.opening_times.openings.mon)}</td>
                  </tr>
                  <tr>
                    <td>{t('Tuesday')}</td><td>{this.getTimesByType(item.opening_times.openings.tue)}</td>
                  </tr>
                  <tr>
                    <td>{t('Wednesday')}</td><td>{this.getTimesByType(item.opening_times.openings.wed)}</td>
                  </tr>
                  <tr>
                    <td>{t('Thursday')}</td><td>{this.getTimesByType(item.opening_times.openings.thu)}</td>
                  </tr>
                  <tr>
                    <td>{t('Friday')}</td><td>{this.getTimesByType(item.opening_times.openings.fri)}</td>
                  </tr>
                  <tr>
                    <td>{t('Saturday')}</td><td>{this.getTimesByType(item.opening_times.openings.sat)}</td>
                  </tr>
                  <tr>
                    <td>{t('Sunday')}</td><td>{this.getTimesByType(item.opening_times.openings.sun)}</td>
                  </tr>
                </tbody>
               :
                <tbody>
                  {item.opening_times.holidays.map((h, i) =>
                  <tr key={'tr_' + i}>
                    <td>{moment(h.date).format('LL')}</td>
                    <td>{this.getTimesByType(h)}</td>
                  </tr>)}
                </tbody>}
              </table>
            </div>
    );
  }

  render() {
    const doc = this.props.doc;
    return (
      <main>
        <div className="container">
          <h1>{RichText.asText(doc.data.title)}</h1>
            <RichText render={doc.data.text} linkResolver={this.props.prismicCtx.linkResolver} htmlSerializer={htmlSerializer} />
            <SliceZone
              sliceZone={doc.data.body}
              prismicCtx={this.props.prismicCtx}
            />
          <h2>{t('Opening hours')}</h2>
          <div className="row mb-4">
            {doc.data.opening_times_group && doc.data.opening_times_group.map((item, i) => this.getOpeningTimesGroup(item, i))}
          </div>
          <div className="row mb-2">
            <div className="col-12">
              <h2>{t('Address')}</h2>
              <h3 className="font-weight-normal text-black">{doc.data.desk_info.address1}, {doc.data.desk_info.city}</h3>
              <LocationMap center={{ lat: doc.data.desk_info.latitude, lng: doc.data.desk_info.longitude }} />
            </div>
          </div>
        </div>
      </main>
    );
  }
}

import GoogleMapReact from 'google-map-react';
const Marker = ({ text }) => <div className="icon icon-location">{text}</div>;
Marker.propTypes = {
  text: PropTypes.string
};
class LocationMap extends React.Component {
  static propTypes = {
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number
    }),
    zoom: PropTypes.number
  }
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 15
  };

  handleApiLoaded(map, maps) {
    return new maps.Marker({
      position: {
        lat: this.props.center.lat,
        lng: this.props.center.lng
      },
      map
    });
  }

  render() {
    return (
      <div style={{ height: '60vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: window.conf.googleMapsApiKey }}
          yesIWantToUseGoogleMapApiInternals
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        >
        </GoogleMapReact>
      </div>
    );
  }
}
